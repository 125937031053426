import { createSelector } from 'reselect'

export const selectToken = state => state.config.token
export const selectTimezone = state => state.config.timezone

export const selectReports = state => state.reports.reports.slice(0, 10)
export const selectAllReports = state => state.reports.reports

export const selectVisibleLocationIds = state =>
  state.locations.allIds.filter(id => state.locations.isVisibleById[id])
export const selectLocationIds = state => state.locations.allIds
export const selectLocationVisibleById = state => state.locations.isVisibleById
export const selectLocationsById = state => state.locations.allById
export const selectLocationOptions = createSelector(
  selectLocationIds,
  selectLocationsById,
  selectLocationVisibleById,
  (locationIds, locationsById, visibleById) => {
    return locationIds.map(locationId => {
      const location = locationsById[locationId]

      return {
        label: location.name,
        value: locationId,
        selected: visibleById[locationId]
      }
    })
  }
)

export const selectProjectIds = state => state.projects.allIds
export const selectProjectVisibleById = state => state.projects.isVisibleById
export const selectProjectsById = state => state.projects.allById
export const selectProjectIdsByLocation = state => state.projects.idsByLocation
export const selectVisibleProjectIds = createSelector(
  selectProjectVisibleById,
  selectVisibleLocationIds,
  selectProjectIdsByLocation,
  (mapperObject, visibleLocationIds, projectIdsByLocation) => {
    return Object.entries(mapperObject)
      .filter(
        ([id, isVisible]) =>
          visibleLocationIds.some(locationId =>
            (projectIdsByLocation[locationId] || []).includes(+id)
          ) && isVisible
      )
      .map(e => e[0])
  }
)
export const selectAvailableProjectsById = createSelector(
  selectProjectsById,
  selectVisibleLocationIds,
  selectProjectIdsByLocation,
  (projectsById, visibleLocationIds, projectIdsByLocation) => {
    const result = {}

    visibleLocationIds.forEach(locationId => {
      (projectIdsByLocation[locationId] || []).forEach(projectId => {
        result[projectId] = projectsById[projectId]
      })
    })

    return result
  }
)
export const selectProjectOptions = createSelector(
  selectProjectIds,
  selectAvailableProjectsById,
  selectProjectVisibleById,
  (projectIds, projectsById, visibleById) => {
    return projectIds
      .map(projectId => {
        const project = projectsById[projectId]

        if (!project) {
          return null
        }

        return {
          label: project.name,
          value: projectId,
          selected: visibleById[projectId]
        }
      })
      .filter(e => e !== null)
  }
)

export const selectTopScrapType = createSelector(
  selectAllReports,
  allReports => {
    if (allReports.length === 0) return null

    return allReports[0].id
  }
)

export const selectScrapTypes = createSelector(
  selectAllReports,
  topScrap => topScrap.map(e => ({ id: e.id, label: e.symbol }))
)

export const selectActiveType = state => state.typeReports.activeType || { x: 1 }

export const selectTypeReports = state => state.typeReports.reports || []

export const selectReportSubject = state => state.reports.subject
